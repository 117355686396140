import React from 'react'
import study from '../pages/study.jpg'

const BuddyLogo = () => {
  return (
    <img src={study} alt="Buddy-logo" className="img-fluid" />
  );
};
 

export default BuddyLogo
